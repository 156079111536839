import React from "react"
import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { BsBookmarkCheck } from "react-icons/bs"
import { AiOutlineHome, AiOutlineShoppingCart } from "react-icons/ai"

const AboutUsPage = () => {
  return (
    <>
      <Seo title="About us" />
      <Breadcrumbs title={"About us"} />

      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-center mt-5 pt-5 mb-5">
            <img
              alt="Owita Naturals"
              src={require("../../images/logo_mobile.png").default}
              style={{ maxWidth: "255px" }}
            />
            <h2 className={"mt-5"}>Stay fit. Healthy Food.</h2>
            <p>
              Owita Naturals is the ideal fulfillment for you to enjoy organic,
              fresh and healthy food from Sri Lanka.
            </p>
          </div>

          <div className="col-sm-6 col-md-4 offset-md-2 text-center px-5">
            <img
              src={require("./farms.png").default}
              alt=""
              style={{ maxWidth: "128px" }}
            />
            <h3>50+</h3>
            <p>Acres of land cultivated with organic vegetables and fruits</p>
          </div>
          <div className="col-sm-6 col-md-4 text-center px-5">
            <img
              src={require("./farmers.png").default}
              alt=""
              style={{ maxWidth: "128px" }}
            />
            <h3>25+</h3>
            <p>Dedicated farmers cultivating certified organic food</p>
          </div>
        </div>
      </div>

      <div className={"line mt-5"}></div>

      <div className="container text-center mt-5">
        <div className="row">
          <div className="col-sm-12">
            <h2>Why us?</h2>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <BsBookmarkCheck />
            </div>
            <h4>Certified Organic Food</h4>
            <p>
              All the items sold by Owita are made by the Certified Organic
              Farmers.
            </p>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <AiOutlineHome />
            </div>
            <h4>Home Delivery</h4>
            <p>Home delivery can be arranged on request.</p>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <AiOutlineShoppingCart />
            </div>
            <h4>Online Purchasing</h4>
            <p>
              You can order directly from the website. We are getting ready to
              integrate online payments soon.
            </p>
          </div>

          {/*<div className="col-sm-6 col-lg-3">*/}
          {/*  <div className={styles.round_icon}>*/}
          {/*    <BsClock />*/}
          {/*  </div>*/}
          {/*  <h4>24x7 Open</h4>*/}
          {/*  <p>Last but not least, We are open everyday...</p>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}

export default AboutUsPage
